import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import cart from '../images/cart.svg';
import profile from '../images/profile.svg'
import logo from '../images/PR1_swaggys.svg'
import { useEffect, useState } from 'react';
import { useUserToggleContext } from "../providers/userProvider";
import ModalTemplate from '../components/ModalTemplate';

function Header({onUpdateStore}) {
	const [navbarOpen, setNavbarOpen] = useState(false);
	const [openReferModal, setOpenReferModal] = useState(false);
	const [openFeedBackModal, setOpenFeedBackModal] = useState(false);
	// <script src="https://pr1stddemoblob17263.blob.core.windows.net/public/digitaladcdn/pr-digitalad-main.js"></script>

	function UpdateStore() {
		// if( document.querySelector('pr-digital-ad') != null) {
		// 	document.querySelector('pr-digital-ad').setAttribute('store', "Olivia's Store") ;
		// }
		  //'Pleasanton' is the client key of the store

		  if(onUpdateStore){
			onUpdateStore();
		  }
	};
	
	const handleLogin = useUserToggleContext();

	const toggleIcon = () => {
		const menuIcon = document.querySelector(
			".navbar-toggler-icon"
		);

		if (navbarOpen) {
			menuIcon.classList.add("navbar_toggler_icon_closed");
			menuIcon.classList.add("show-logo");
		} else {
			menuIcon.classList.remove("navbar_toggler_icon_closed");
			menuIcon.classList.remove("show-logo");
		}
	};

	useEffect(() => {
		toggleIcon(navbarOpen);
	}, [navbarOpen]);

	function openReferPopup() {
		setOpenReferModal(true);
	}

	function openFeedbackSurveyPopup() {
		setOpenFeedBackModal(true);
	}

	return (
		<>
			<Navbar expand="lg" className="blue-bg top-nav">
				<Container>
					<Navbar.Toggle aria-controls="basic-navbar-nav"
						onClick={() => {
							setNavbarOpen((navbarOpen) => !navbarOpen);
						}}
					/>
					<Navbar.Brand className='flex-grow-1'>
						<Link to="/">
							<img src={logo} alt="Logo" className="logo"></img>
						</Link>
					</Navbar.Brand>
					<div className='d-block d-lg-none'>
						<button onClick={openFeedbackSurveyPopup} className="link"><img className="cart" src={cart} alt="Shopping Cart"></img></button>
					</div>
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="ms-auto">

							<Nav.Item className="nav-item">
								<Link to="/weekly-ad" className="link"> Weekly Ad</Link>
								<Link to="/coupon-hub" className="link"> Digital Coupons</Link>
								<button onClick={openReferPopup} className="link">Refer-a-Friend</button>
							</Nav.Item>
							<div className='d-block d-lg-none'>
								<button onClick={UpdateStore} className="link"><img src={profile} alt="Login Logo"></img>Login</button>
							</div>
							<div className='d-none d-lg-block'>
								<button onClick={UpdateStore} className="link"><img src={profile} alt="Login Logo"></img></button>
							</div>
							<div className='d-none d-lg-block'>
								<button onClick={openFeedbackSurveyPopup} className="link"><img src={cart} alt="Shopping Cart"></img></button>
							</div>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			<ModalTemplate open={openReferModal} onClose={() => setOpenReferModal(false)} typeModal={'referPopup'} />
			<ModalTemplate open={openFeedBackModal} onClose={() => setOpenFeedBackModal(false)} typeModal={'feedbackSurveryPopup'} />
		</>
	);
}

export default Header;