import Footer from "../partials/footer";
import Header from "../partials/header";
import React from "react";
import "../styles/index.scss";
import { useEffect, useRef } from "react";

function Ad() {
  const isInitialized = useRef(false);
  let prDigitalAd = null;

  let cartList = [];
  let couponList = [];

  useEffect(() => {
    if (window.PRDigitalAd) {
      if (isInitialized.current) return;
      initializeAd();
      isInitialized.current = true;
    } else {
      console.error('PRDigitalAd is not available in the global window object');
    }

    return () => {
    };
  }, []);

  const initializeAd = () => {
    // Define callback methods for Cart and Coupons
    const addToCartCallback = (obj) => {
      console.log('Add to Cart method init...', obj.items[0]);
      // Handle cart logic here...
      let index = cartList.findIndex(cart => cart.productId === obj.items[0].productId);

      if (index == -1) {
          cartList.push(obj.items[0]);
      }

      obj.callback({
          status: 'success',
          message: 'Add to cart success!!!',
          cartItems: cartList,
          details: obj.items,
      });
    };

    const removeFromCartCallback = (obj) => {
      console.log('Remove from Cart method init...', obj.items[0]);
      // Handle remove cart logic here...
      let index = cartList.findIndex(cart => cart.productId === obj.items[0].productId);

      if (index > -1) {
          cartList.splice(index, 1);
      }

      obj.callback({
          status: 'success',
          message: 'Remove from Cart success!!!',
          cartItems: cartList,
          details: obj.items,
      });
    };

    const clipCouponCallback = (obj) => {
      console.log('Clip Coupon method init...');
      // Handle coupon clipping logic here...
      let index = couponList.findIndex(coupon => coupon.couponId === obj.items[0].couponId);

      if (index == -1) {
          couponList.push(obj.items[0]);
      }

      obj.callback({
          status: 'success',
          message: 'Clip Coupon success!!!',
          clippedCoupons: couponList,
          details: obj.items
      });
    };

    const unclipCouponCallback = (obj) => {
      console.log('Unclip Coupon method init...');
      // Handle unclip coupon logic here...
      let index = couponList.findIndex(coupon => coupon.couponId === obj.items[0].couponId);

      if (index > -1) {
          couponList.splice(index, 1);
      }

      obj.callback({
          status: 'success',
          message: 'UnClip Coupon success!!!',
          clippedCoupons: couponList,
          details: obj.items
      });
    };

    const selectStore = () => {
      console.log('Select Store method init!');
      // Handle store selection logic here...
    };

    const config = {
      containerId: 'pr-ad-holder',
      // storeKey: '',
      // viewMode: 'live',
      apiKey: 'pgH7QzFHJx4w46fI~5Uzi4RvtTwlEXp5', // Use actual API key
      cartItems: [],
      clippedCoupons: [],
      onAddToCart: addToCartCallback,
      onRemoveFromCart: removeFromCartCallback,
      onClipCoupon: clipCouponCallback,
      onUnclipCoupon: unclipCouponCallback,
      selectStoreHandler: selectStore,
      storeLocatorEnabled: true,
    };

    prDigitalAd = new window.PRDigitalAd(config);
    console.log("PRDigitalAd : ", prDigitalAd);
    // const prDigitalAd1 = new window.PRDigitalAd(config);
    // console.log("PRDigitalAd1 : ", prDigitalAd1);
  };

  const UpdateStoreCB = () => {
    prDigitalAd.setStore("Olivia's Store"); //Pleasanton, Denton, StoneMountain
  };

  return (
    <div className="Ad">
      <Header onUpdateStore={UpdateStoreCB}></Header>
      <div id="pr-ad-holder"></div>
      <Footer></Footer>
    </div>
  );
}

export default Ad;